import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'

import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: "img/mp-pg1.jpg" },
  { url: "img/mp-pg2.jpg" },
  { url: "img/mp-pg3.jpg" },
  { url: "img/mp-pg4.jpg" },
  { url: "img/mp-pg5.jpg" },
  { url: "img/mp-pg6.jpg" },
  { url: "img/mp-pg7.jpg" },
  { url: "img/mp-pg8.jpg" },
];


export const IndexPageTemplate = ({
  image,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
      }}
    >
      <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen" style={{
        boxShadow: 'rgb(51, 153, 51) 0.5rem 0px 0px, rgb(51, 153, 51) -0.5rem 0px 0px',
        backgroundColor: 'rgb(51, 153, 51)',
        color: 'white',
        lineHeight: '1',
        padding: '0.25em'
      }} >
        Classic Air Conditioning
      </h1>
    </div>

    <section className="section--gradient">
      <div className="container">
        <div>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="columns">
                  <div className="column is-12 main-head">
                    {description}
                  </div>
                </div>
                <br />
                <Features gridItems={intro.blurbs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="section mp-img-div">
      <SimpleImageSlider
        width={1000}
        height={500}
        images={images}
        showBullets={true}
        showNavs={true}
      />
    </div>


  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainpitch {
          title
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
            style
          }
        }
      }
    }
  }
`
