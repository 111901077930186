import React from 'react'
import PropTypes from 'prop-types'

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    <div key="f1key" className="column fet1">
      <section className="section">
        <p className="fet-text"> Installation and replacement of all A/C brands</p>
      </section>
    </div>
    <div key="f2key" className="column fet2">
      <section className="section">
        <p className="fet-text">Sales and Service for Godrej Air conditioners</p>
      </section>
    </div>
    <div key="f3key" className="column fet1">
      <section className="section">
        <p className="fet-text">Specialized in A/C installation for high-rise buildings</p>
      </section>
    </div>
    <div key="f4key" className="column fet2">
      <section className="section">
        <p className="fet-text">Flexible Annual Maintenance Contract (AMC) Plans</p>
      </section>
    </div>
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
